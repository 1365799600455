import React from 'react';

import './modal.css';
import $ from 'jquery';


$(function() {
  //open popup
  $('.popup').addClass('is-visible');
  
  //close popup
  $('.popup').on('click', function(event){
    if( $(event.target).is('.popup-close') || $(event.target).is('.popup') ) {
      event.preventDefault();
      $(this).removeClass('is-visible');
    }
  });
});


export function Vacation() {
  return (
    <section>
      <div className="popup" role="alert">
        
        <div className="popup-container sm:container-mob">
          <a href="#0" className="popup-close img-replace">Close</a>
          
          <p className="font-bold text-2xl sm:text-3xl">
            DOVOLENÁ
          </p>

          <br />

          <p className="font-bold text-xl xs:text-xl">
          Zubní ordinace neordinuje ve dnech
          Vážení pacienti, 
          
          {/* <br />
          // ordinace bude uzavřena ve dnech  */}

          <br />
          <br />

          2.12.2024. - 6.12.2024
          </p>

          <br />

          <p className="font-normal text-lg xs:text-base">
            {/* V případě akutní bolesti navštivte pohotovost - Most, Bílina */}
            V případě akutní bolesti našich registrovaných pacientů
            <br />

            <br />

            <p className="font-bold text-xl xs:text-3xl">
               MDDr. Václav Bílek <br /> 
            </p>

            <br />

            Tržní 508, Litvínov <br />

            každý den <br />
            7:00 – 10:00 <br /><br />

            
            <p className="font-bold text-xl xs:text-3xl">
              tel: 725 980 113
            </p>
            
          </p>

        </div> 

      </div> 

    </section>
  );
}

export default Vacation